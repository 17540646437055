import React, { Fragment } from 'react';
import { host, pesan, api, submitForm, openModal } from '../Modul';
import RendTables from '../component/RendTable';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            DataDb: [],
            ID: "",
            q: "",
            Password: "",
            Database: "",
            SQL: "",
            Soal: {},
            Jawaban: "",
            Progress: 0,
            JenisReset: "Transaksi",
            DataTables: [],
            FieldTables: [],
            DataQuery: [],
            FieldQuery: [],
            Query: ""
        };
    }

    async componentDidMount() {
        this.handleMain();
        let Field = [
            { cap: "", sort: "", type: "opsi" },
            { cap: "Nama", sort: "Nama", type: "str" },
            { cap: "Database", sort: "Database", type: "str" },
            { cap: "Status", sort: "SSTS", type: "str" },
            { cap: "Ukuran", sort: "DBSize", type: "str" }
        ];

        this.setState({ Field: Field });
        let ColorTheme = localStorage.getItem("ColorTheme") || "dark";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("database_api", { act: "data", q: this.state.q });
        if (sql.status == "sukses") this.setState({ Data: sql.data });
    }

    async handleBackup(e) {
        let sql = await api("database_api", { act: "backup db", Database: e.Database });
        if (sql.status == "sukses") {
            window.open(sql.pesan);
            pesan(sql.status, "Berhasil backup database", "primary");
        } else {
            pesan(sql.status, sql.pesan, "danger");
        }
    }

    async handleChangeStatus(e, Status) {
        let sql = await api("database_api", { act: "edit status", ID: e.ID, Status: Status });
        if (sql.status == "sukses") {
            this.handleMain();
            pesan(sql.status, sql.pesan, "primary");
        } else {
            pesan(sql.status, sql.pesan, "danger");
        }
    }

    async handleModalBackup() {
        let sql = await api("database_api", { act: "data database backup" });
        if (sql.status == "sukses") {
            this.setState({ DataDb: sql.data });
            openModal("modalBackup");
        }
    }

    async handleModalSchema() {
        let sql = await api("database_api", { act: "data database" });
        if (sql.status == "sukses") {
            this.setState({ DataDb: sql.data });
            openModal("modalSchema");
        }
    }

    async handleSchema(e) {
        e.stopPropagation();
        e.preventDefault();
        let Form = e.target;
        let btn = Form.querySelector('button[type="submit"]');
        btn.disabled = true;
        if (e.target.checkValidity()) {
            let data = this.state.DataDb
            for (let dd of data) {
                if (dd.Link == "" || dd.Link != "gagal") {
                    let sql = await api("database_api", { act: "schema db", SQL: this.state.SQL, Database: dd.Database }, true);
                    if (sql.status == "sukses") {
                        dd.Link = sql.pesan;
                    } else {
                        dd.Link = "gagal";
                    }
                    this.setState({ DataDb: data });
                }
            }
        } else {
            Form.classList.add('was-validated');
            btn.disabled = false;
        }
    }

    async handleModalQuery(data) {
        let sql = await api("database_api", { act: "query", Database: data.Database, Query: "SHOW TABLES;" });
        if (sql.status == "sukses") {
            let dd = sql.data;
            let keys = Object.keys(dd[0]);
            let Field = [];
            for (let dd of keys) Field.push({ cap: dd, sort: dd, type: "str" });
            this.setState({ FieldTables: Field, DataTables: sql.data, Detail: data });
            openModal("modalQuery");
        }
    }

    async handleQuery() {
        let Query = this.state.Query;
        if (Query != "") {
            let sql = await api("database_api", { act: "query", Database: this.state.Detail.Database, Query: Query });
            if (sql.status == "sukses") {
                let data = sql.data;
                let keys = Object.keys(data[0]);
                let Field = [];
                for (let dd of keys) Field.push({ cap: dd, sort: dd, type: "str" });
                this.setState({ DataQuery: sql.data, FieldQuery: Field });
            }
        } else {
            pesan("Salah", "Silahkan Masukan query");
        }
    }

    async handlePilihTabel(Tabel) {
        let sql = await api("database_api", { act: "query", Database: this.state.Detail.Database, Query: `SELECT * FROM ${Tabel}` });
        if (sql.status == "sukses") {
            let data = sql.data;
            let keys = Object.keys(data[0]);
            let Field = [];
            for (let dd of keys) Field.push({ cap: dd, sort: dd, type: "str" });
            this.setState({ DataQuery: sql.data, FieldQuery: Field });
        }
    }

    async prosesBackup(e) {
        e.target.disabled = true;
        let data = this.state.DataDb
        for (let dd of data) {
            if (dd.Link == "" || dd.Link != "gagal") {
                let sql = await api("database_api", { act: "backup db", Database: dd.Database }, true);
                if (sql.status == "sukses") {
                    dd.Link = sql.pesan;
                } else {
                    dd.Link = "gagal";
                }
                this.setState({ DataDb: data });
            }
        }
        e.target.disabled = false;
    }

    async prosesDownload(e) {
        e.target.disabled = true;
        let sql = await api("database_api", { act: "backup all" }, true);
        if (sql.status == "sukses") {
            window.open(sql.pesan);
            e.target.disabled = false;
        } else {
            pesan("Gagal", "Terjadi kesalahan", "danger");
            e.target.disabled = false;
        }
    }

    handleModalReset(data) {
        let Soal = [
            { q: "Siapa Nama Anak pak Heru Yang Pertama", a: "nayla" },
            { q: "Siapa Nama Anak pak Heru Yang Kedua", a: "aneira" },
            { q: "Siapa Nama cs pari tunggal yang dekat dengan deo", a: "putri" },
            { q: "nama warkop tempat biasanya kerja", a: "anful" },
            { q: "apa nama game favorit pah heru", a: "genshin impact" },
            { q: "siapa nama istri mas ganda", a: "arin" },
        ];
        let Q = Soal[Math.ceil(Math.random() * Soal.length - 1)];
        this.setState({ Soal: Q, Database: data.Database });

        openModal("modalReset");
    }

    async handleReset(e) {
        e.preventDefault();
        e.stopPropagation();
        let Form = e.target;
        let btn = Form.querySelector('button[type="submit"]');
        btn.disabled = true;
        if (this.state.Jawaban == this.state.Soal.a) {
            let sql = await api("database_api", { act: "reset db", Database: this.state.Database, JenisReset: this.state.JenisReset }, true);
            if (sql.status == "sukses") {
                btn.disabled = false;
                pesan("Berhasil", sql.pesan, "primary");
                document.getElementById('btnTutupModalReset').click();
            } else {
                btn.disabled = false;
                pesan("Gagal", sql.pesan, "danger");
            }
        } else {
            btn.disabled = false;
            pesan("Gagal", "Jawaban salah", "danger");
        }
    }

    handleModalRestore(data) {
        let Soal = [
            { q: "Siapa Nama Anak pak Heru Yang Pertama", a: "nayla izzati prasetia" },
            { q: "Siapa Nama Anak pak Heru Yang Kedua", a: "aneira ghani prasetia" },
            { q: "Siapa Nama ibu pak Heru", a: "mas amah" },
            { q: "Siapa Nama cs pari tunggal yang dekat dengan deo", a: "putri" },
            { q: "nama warkop tempat biasanya kerja", a: "anful" },
            { q: "apa nama game favorit pah heru", a: "genshin impact" },
        ];
        let Q = Soal[Math.ceil(Math.random() * Soal.length - 1)];
        this.setState({ Soal: Q, Database: data.Database, Progress: 0 });

        openModal("modalRestore");
    }

    async handleRestore(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const response = await fetch(host + "database_api", {
            method: 'POST',
            body: formData,
        });

        const reader = response.body.getReader();
        const contentLength = +response.headers.get('Content-Length');

        let receivedLength = 0;
        let chunks = [];

        while (true) {
            const { done, value } = await reader.read();

            if (done) {
                break;
            }

            chunks.push(value);
            receivedLength += value.length;

            const percentComplete = Math.floor((receivedLength / contentLength) * 100);
            this.setState({ Progress: percentComplete });
        }

        const responseBody = new TextDecoder("utf-8").decode(new Uint8Array(chunks.flat()));
        try {
            const data = JSON.parse(responseBody);
            if (data.status === 'progress') {
                this.setState({ Progress: data.progress });
            } else if (data.status === 'sukses') {
                this.setState({ Progress: 100 });
                pesan("Berhasil", data.pesan, "Primary");
            } else {
                pesan("Gagal", data.pesan, "danger");
            }
        } catch (e) {
            pesan("Gagal", "Terjadi kesalahan " + e, "danger");
        }
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2">
                    <button className='btn btn-sm btn-default' onClick={() => this.handleModalBackup()}>Backup</button>
                    <button className='btn btn-sm btn-default' onClick={() => this.handleModalSchema()}>Schema</button>
                </div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">Database</div>
                            <div className="col-md-3 d-flex gap-1">
                                <div className="input-group gap-2">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Konten' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTables tbody={this.state.Data} thead={this.state.Field} opt={[
                            { icon: "fas fa-download", fn: (e) => this.handleBackup(e) },
                            { icon: "fas fa-upload", fn: (e) => this.handleModalRestore(e) },
                            { icon: "fas fa-check-square", fn: (e) => this.handleChangeStatus(e, "1") },
                            { icon: "fas fa-window-close", fn: (e) => this.handleChangeStatus(e, "0") },
                            { icon: "fas fa-sync", fn: (e) => this.handleModalReset(e) },
                            { icon: "fas fa-edit", fn: (e) => this.handleModalQuery(e) }
                        ]} />
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalBackup" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Backup Database</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='tablde-responsive'>
                                    <table className='table table-stripped'>
                                        <thead>
                                            <tr>
                                                <th>Nama</th>
                                                <th>Database</th>
                                                <th>Link</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.DataDb.map((tr, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{tr.Nama}</td>
                                                            <td>{tr.Database}</td>
                                                            <td>{tr.Link != "" ? tr.Link == "gagal" ? "Gagal" : <a href={tr.Link} target='_blank'>Download</a> : ""}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalCariMenu'>Tutup</button>
                                <button type="submit" className="btn btn-primary" id='btnDownload' onClick={(e) => this.prosesDownload(e)}>Download</button>
                                <button type="submit" className="btn btn-primary" id='btnProseBackup' onClick={(e) => this.prosesBackup(e)}>Proses</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Untuk schema */}
                <div className="modal fade" id="modalSchema" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => this.handleSchema(e)} noValidate>
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Schema Database</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>SQL tanpa USES</label>
                                        <textarea className='form-control' value={this.state.SQL} onChange={(e) => this.setState({ SQL: e.target.value })} required />
                                        <div className='invalid-feedback'>Silahkan isi sql</div>
                                    </div>
                                    <p></p>
                                    <div className='table-responsive'>
                                        <table className='table table-stripped'>
                                            <thead>
                                                <tr>
                                                    <th>Nama</th>
                                                    <th>Database</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.DataDb.map((tr, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{tr.Nama}</td>
                                                                <td>{tr.Database}</td>
                                                                <td>{tr.Link}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalSchema'>Tutup</button>
                                    <button type="submit" className="btn btn-danger">Proses</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Untuk query */}
                <div className="modal fade" id="modalQuery" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Query Database</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-3">
                                        <input className='form-control' placeholder='Cari Nama Table' />
                                        <div className="table-responsive">
                                            <table className="table table-stripped">
                                                <tbody>
                                                    {
                                                        this.state.DataTables.map((tr, i) => {
                                                            return (<tr key={i} >
                                                                {
                                                                    this.state.FieldTables.map((td, i) => {
                                                                        return <td onClick={async (e) => this.handlePilihTabel(tr[td.sort])}>{tr[td.sort]}</td>
                                                                    })
                                                                }
                                                            </tr>)
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-9">
                                        <textarea id='estQuery' className='form-control' rows={5} value={this.state.Query} onChange={(e) => this.setState({ Query: e.target.value })} />
                                        <button type="button" className="btn btn-default w-100" onClick={(e) => this.handleQuery()} id="btn">Execute</button>
                                        <p></p>
                                        <RendTables tbody={this.state.DataQuery} thead={this.state.FieldQuery} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalCariMenu'>Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Untuk reset */}
                <div className="modal fade" id="modalReset" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => this.handleReset(e)} noValidate>
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Reset Database</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>{this.state.Soal.q}</h5>
                                    <div className='form-group'>
                                        <label>Jawab pertanyaan diatas</label>
                                        <input type='text' className='form-control' value={this.state.Jawaban} onChange={(e) => this.setState({ Jawaban: e.target.value })} required />
                                        <div className='invalid-feedback'>Silahkan jawab pertanyaan</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Jenis Reset</label>
                                        <select className='form-select' value={this.state.JenisReset} onChange={(e) => this.setState({ JenisReset: e.target.value })} required >
                                            <option value="Transaksi">Transaksi</option>
                                            <option value="Semua">Semua</option>
                                        </select>
                                        <div className='invalid-feedback'>Silahkan pilih jenis reset</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalReset'>Tutup</button>
                                    <button type="submit" className="btn btn-danger">Reset</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Untuk reset */}
                <div className="modal fade" id="modalRestore" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => this.handleRestore(e)} noValidate>
                                <input type='hidden' name="act" value="restore" />
                                <input type='hidden' name="Database" value={this.state.Database} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Restor Database</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>{this.state.Soal.q}</h5>
                                    <div className='form-group'>
                                        <label>Jawab pertanyaan diatas</label>
                                        <input type='text' className='form-control' value={this.state.Jawaban} onChange={(e) => this.setState({ Jawaban: e.target.value })} required />
                                        <div className='invalid-feedback'>Silahkan jawab pertanyaan</div>
                                    </div>

                                    <h5>{this.state.Soal.q}</h5>
                                    <div className='form-group'>
                                        <label>File restore</label>
                                        <input type='file' name="Files" className='form-control' accept=".zip,.sql" required />
                                        <div className='invalid-feedback'>Silahkan Pilih file restore</div>
                                    </div>
                                    <div className="progress" role="progressbar" aria-label="Example with label" aria-valuenow={this.state.Progress} aria-valuemin="0" aria-valuemax="100">
                                        <div className="progress-bar" style={{ width: `${this.state.Progress}%` }}>0%</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalReset'>Tutup</button>
                                    {/* <button type="submit" className="btn btn-danger">Restore</button> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Dashboard;
