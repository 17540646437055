import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Outlet, Navigate } from 'react-router-dom';
import { api, pesan, Token, host, compressImageBase64, resizeImageBase64, submitForm, isJson } from './Modul';
import { requestForToken, onMessageListener } from './firebase';
import Logo from './assets/img/logo.png';
import LogoPanjang from './assets/img/logopanjang.png';
import Profile from './assets/img/profile.png';
import BG2 from './assets/img/bg-2.png';
import './App.css';
import './Login.css';

// PAGE
import Dashboard from './page/Dashboard';
import MenuPelanggan from './page/MenuPelanggan';
import MenuDatabase from './page/MenuDatabase';
import MenuMenu from './page/MenuMenu';
import MenuAdmin from './page/MenuAdmin';
import MenuReqFitur from './page/MenuReqFitur';
import MenuSystem from './page/MenuSystem';
import MenuUser from './page/MenuUser';

function App() {
  class Main extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        Menu: [],
        Group: [],
        CurePage: [],
        NamaLokasi: "Pusat",
        NamaUser: "Heru Prasetia",
        divSetting: [],
        detailSetting: [],
        clsLoading: "fas fa-save",
        btnSubmit: false,
        iconTheme: "fas fa-moon",
        show: false,
        notification: { title: '', body: '' },
        tokenFound: false
      };
    }

    async componentDidMount() {
      requestForToken(this.setTokenFound);
      let sql = await api("data", { act: "data menu" });
      if (sql.status == "sukses") {
        let Menu = [], Group = [
          { Nama: "Konten", Icon: "fas fa-images", Posisi: "konten", Active: false },
          { Nama: "Master", Icon: "fas fa-th-list", Posisi: "master", Active: false },
          { Nama: "Artikel", Icon: "fas fa-newspaper", Posisi: "artikel", Active: false },
        ];
        for (let menu of sql.data) {
          Menu.push(menu);
          for (let gg in Group) if (Group[gg].Posisi == menu.Posisi) Group[gg].Active = true;
        }
        let ColorTheme = localStorage.getItem("ColorTheme") || "dark";
        if (ColorTheme == "dark") {
          document.body.classList.add('dark-theme');
          let cls = document.getElementsByClassName("table");
          for (let i = 0; i < cls.length; i++) {
            cls[i].classList.add("table-dark");
          }
          let clsOffCanvas = document.getElementsByClassName("offcanvas");
          for (let i = 0; i < clsOffCanvas.length; i++) {
            clsOffCanvas[i].classList.add("text-bg-dark");
          }
          let clsCard = document.getElementsByClassName("card");
          for (let i = 0; i < clsCard.length; i++) {
            clsCard[i].classList.add("text-bg-dark");
          }
        }

        if (ColorTheme == "dark") {
          document.getElementById("theme-link").href = "https://cdn.jsdelivr.net/npm/devextreme@23.2/dist/css/dx.darkviolet.css";
        } else {
          document.getElementById("theme-link").href = "https://cdn.jsdelivr.net/npm/devextreme@23.2/dist/css/dx.greenmist.css";
        }
        this.setState({ Menu: Menu, Group: Group, iconTheme: ColorTheme == "light" ? "fas fa-moon" : "fas fa-sun" });
        // console.clear();
        console.log(sql);
      } else {
        this.handleLogout();
      }
    }

    setTokenFound = async (tokenFound, token) => {
      if (tokenFound) {
        await api("user_api", { act: "set token", TokenFireBase: token }, true);
        this.setState({ tokenFound });
      }
    };

    handleColaps() {
      document.getElementById("sidebar").style.display = "block";
      document.getElementById("sidebar").classList.toggle("hide");
    }

    handlePilihMenu(Menu, Title) {
      let clsMenu = document.getElementsByClassName("menu");
      for (let i = 0; i < clsMenu.length; i++) clsMenu[i].classList.remove("active");
      document.getElementById('menu' + Menu).classList.add("active");
      document.title = Title;
    }

    handleLogout() {
      localStorage.clear();
      window.location.reload();
    }

    handleChangeTheme() {
      let ColorTheme = localStorage.getItem("ColorTheme") || "dark";
      if (ColorTheme == "light") {
        localStorage.setItem("ColorTheme", "dark");
        document.getElementById("theme-link").href = "https://cdn.jsdelivr.net/npm/devextreme@23.2/dist/css/dx.darkviolet.css";
        this.setState({ iconTheme: "fas fa-sun" });
      } else {
        localStorage.setItem("ColorTheme", "light");
        document.getElementById("theme-link").href = "https://cdn.jsdelivr.net/npm/devextreme@23.2/dist/css/dx.greenmist.css";
        this.setState({ iconTheme: "fas fa-moon" });
      }
      document.body.classList.toggle('dark-theme');
      let cls = document.getElementsByClassName("table");
      for (let i = 0; i < cls.length; i++) {
        cls[i].classList.toggle("table-dark")
      }
    }

    handleCariMenu(val) {
      if (val !== "") {

      } else {
        let Menu = this.state.Menu, Group = [
          { Nama: "Konten", Icon: "fas fa-images", Posisi: "konten", Active: false },
          { Nama: "Master", Icon: "fas fa-th-list", Posisi: "master", Active: false }
        ];
        for (let menu of Menu) {
          Menu.push(menu);
          for (let gg in Group) if (Group[gg].Posisi == menu.Posisi) Group[gg].Active = true;
        }
      }
    }

    render() {
      return (
        <Router>
          <div className="wrapper">
            <nav id="sidebar" className="sidebar">
              <div className="sidebar-header cursor">
                <img src={LogoPanjang} width="90%" className="logo-panjang" />
                <img src={Logo} style={{ width: "50px", height: "50px" }} className="logo" />
              </div>
              <div className="transbox">
                <input type="search" name="qMenu" id="qMenu" className="form-control form-control-sm" placeholder="[F2] Cari Menu . . ."
                  onFocus={(e) => e.target.select()} autoComplete="off" />
                <ul className="list-unstyled components" id="ulMenu">
                  {
                    this.state.Menu.map((li, i) => {
                      if (li.Posisi == "root") {
                        return (
                          <li className="menu" id={"menu" + li.Path} key={li.Path} onClick={() => this.handlePilihMenu(li.Path, li.Nama)}>
                            <Link to={`/${li.Path}`}>
                              <i className={li.Icon}></i> <span className="nama-menu">{li.Nama}</span>
                            </Link>
                          </li>
                        )
                      }
                    })
                  }
                  {
                    this.state.Group.map((ul, i) => {
                      if (ul.Posisi != "root") {
                        let Menus = [];
                        for (let dd of this.state.Menu) if (dd.Posisi == ul.Posisi) Menus.push(dd);
                        if (Menus.length > 0) {
                          return (
                            <li key={i}>
                              <a href={`#GroupMenu${ul.Posisi}`} data-bs-toggle="collapse" aria-expanded="false" className="dropdown-toggle" key={ul.Posisi}>
                                <i className={ul.Icon}></i>
                                <span className="nama-menu">{ul.Nama}</span>
                              </a>
                              <ul className="collapse list-unstyled" id={`GroupMenu${ul.Posisi}`}>
                                {
                                  Menus.map((li, ii) => {
                                    return (
                                      <li className="menu" id={"menu" + li.Path} key={li.Path} onClick={() => this.handlePilihMenu(li.Path, li.Nama)}>
                                        <Link to={`/${li.Path}`}>
                                          {li.Nama}
                                        </Link>
                                      </li>)
                                  })
                                }
                              </ul>
                            </li>
                          )
                        }
                      }
                    })
                  }
                </ul>
              </div>
            </nav >
            <div id="content">
              <nav className="navbar">
                <div className="container-fluid d-flex nav-container">
                  <button type="button" id="sidebarCollapse" className="btn btn-sm nav-menu" onClick={() => this.handleColaps()}> <i className="fas fa-bars"></i> </button>
                  <div className="d-flex justify-content-end align-items-center nav-menu-items">
                    <button type="button" className="btn align-items-center nav-menu" style={{ position: "relative" }} onClick={() => this.handleChangeTheme()}>
                      <i className={this.state.iconTheme}></i>
                    </button>
                    <div className="dropdown ">
                      <button className="dropdown-toggle nav-profile" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <div className="nav-profile-photo">
                          <img src={Profile} />
                        </div>
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end shadow-lg dropdown-menu-dark" style={{ minWidth: "14rem", fontSize: "0.9rem", maxHeight: "440px", overflowY: "auto" }}>
                        <li className="dropdown-item">
                          <a><b>Login Sebagai : </b></a>
                          <br></br>
                          <p className="mt-2 d-flex align-items-end">
                            <i className="fas fa-user"></i>
                            <span id='spanUserName'>{this.state.NamaUser}</span>
                          </p>
                        </li>
                        <li>
                          <hr className="dropdown-divider"></hr>
                        </li>
                        <li><span onClick={() => this.handleLogout()} className="dropdown-item text-center"><i className="fas fa-logout"></i> Keluar</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
              <div style={{ height: "fit-content", border: "none" }} id="tampil">
                <Routes>
                  <Route path="/" element={<Dashboard page="Home" />} />
                  <Route path="/dashboard" element={<Dashboard page="Home" />} />
                  <Route path="/manage-bussiness" element={<MenuPelanggan />} />
                  <Route path="/manage-db" element={<MenuDatabase />} />
                  <Route path="/manage-menu" element={<MenuMenu />} />
                  <Route path="/manage-admin" element={<MenuAdmin />} />
                  <Route path="/reqfitur" element={<MenuReqFitur />} />
                  <Route path="/system" element={<MenuSystem />} />
                  <Route path="/manage-user" element={<MenuUser />} />
                </Routes>
              </div>
              <div id="divPesan"></div>
            </div>
          </div>
        </Router >
      )
    }
  }

  class Login extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        Email: "",
        Pwd: "",
        clsLogin: "",
        btnLogin: false
      };
    }

    async handleLogin(e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.target.checkValidity()) {
        this.setState({ btnLogin: true, clsLogin: "spinner-border spinner-border-sm text-light" });
        let data = new FormData(e.target);
        fetch(host + "login", {
          method: 'POST',
          body: data,
        }).then(response => response.text()).then(res => {
          if (isJson(res)) {
            let hasil = JSON.parse(res);
            if (hasil.status === "sukses") {
              localStorage.setItem("TokenAdmin", hasil.token);
              window.location.reload();
            } else {
              this.setState({ btnLogin: false, clsLogin: "" });
              pesan("Login Gagal", hasil.pesan, "primary");
            }
          } else {
            console.log(res);
            pesan("Login Gagal", "Terjadi kesalahan", "primary");
          }
        }).catch((error) => {
          this.setState({ btnLogin: false, clsLogin: "" });
          console.log("Error: " + error);
        });
      } else {
        let forms = document.getElementsByClassName('needs-validation');
        let ululu = Array.prototype.filter.call(forms, function (form) {
          form.classList.add('was-validated');
        });
      }
    }

    handleVisible(e) {
      let cls = e.target;
      let edtPwd = document.getElementById('edtPwd');
      if (edtPwd.type == "password") {
        cls.classList.remove("fa-eye-slash");
        cls.classList.add("fa-eye");
        edtPwd.type = "text";
      } else {
        cls.classList.add("fa-eye-slash");
        cls.classList.remove("fa-eye");
        edtPwd.type = "password";
      }
    }

    render() {
      return (
        <section>
          <div className="row">
            <div className="col-md-8">
              <div className="div-img-top">
                <img src={LogoPanjang} className="logo" alt="Logo" />
              </div>
              <div className="card-form">
                <form id="formLogin" className="needs-validation" onSubmit={(e) => this.handleLogin(e)} noValidate>
                  <h4>Login</h4>
                  <h6>Selamat datang kembali di NaylaTools Software</h6>
                  <div className="form-group">
                    <label>Email</label>
                    <input type="email" name="Email" className="form-control form-control-lg" id="edtEmail" value={this.state.Email} onChange={(e) => this.setState({ Email: e.target.value })} required />
                    <div className="invalid-feedback">Silahkan masukan alamat email</div>
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <div className="input-group">
                      <input type="password" name="Password" className="form-control form-control-lg" id="edtPwd" value={this.state.Pwd} onChange={(e) => this.setState({ Pwd: e.target.value })} required />
                      <div className="invalid-feedback">Silahkan masukan password</div>
                      <i className="fas fa-eye-slash btn-opsi" id="btnVisibility" onClick={(e) => this.handleVisible(e)}></i>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end" style={{ marginBottom: "24px" }}>
                    <span className="btn-link" style={{ fontWeight: "bold", cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#modal">Lupa Password?</span>
                  </div>
                  <button className=" btn btn-lg w-100 btn-login" id="btnLogin">
                    <i id="lblLoading" className={this.state.clsLogin}></i> Login
                  </button>
                  <div style={{ textAlign: "center", marginTop: "24px" }}>
                    <span>Belum mempunyai akun? <a href="https://www.naylatools.com/" className="btn-link" style={{ fontWeight: "bold", cursor: "pointer" }}>Lakukan Pendaftaran</a></span>
                  </div>
                  <div className="alert alert-danger" id="lblNotif" style={{ display: "none", marginTop: "1rem" }} role="alert"></div>
                </form>
              </div>
            </div>
            <div className="col-md-4" style={{ padding: "0px" }}>
              <div className="bg-side">
                <img src={BG2} className="img-side-bottom"></img>
              </div>
            </div>
            <div id='divPesan'></div>
          </div>

          <div className="modal fade" id="modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <form id="formForgot" className="needs-validation" noValidate>
                  <div className="modal-header" id="modalHeader">
                    <h5 className="modal-title" id="exampleModalLabel">Lupa Password</h5>
                    <button type="button" className="btn btn-close-modal" data-bs-dismiss="modal" id="dismissModal">
                      <span className="material-icons">close</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <input type="hidden" name="act" value="request" />
                    <div className="form-group">
                      <label>Alamat Email</label>
                      <input type="email" name="email" className="form-control form-control-lg" id="edtEmailForgot" placeholder="Silahkan masukan alamat email anda" required />
                      <div className="invalid-feedback">Silahkan Masukan Alamat Email</div>
                    </div>
                    <p></p>
                    <div className="alert alert-danger" id="divAlert" style={{ display: "none" }} role="alert"></div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Batal</button>
                    <button type="submit" className="btn btn-login" id="btnForgot"><i id="lblDaftar" disabled></i> Kirim Permintaan</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )
    }
  }

  if (Token) {
    return <Main />
  } else {
    return <Login />
  }
}

export default App;
